import React, { useEffect, useState } from 'react'
import { AutoComplete, Button, Checkbox, Col, Divider, Form, Input, List, Modal, Popconfirm, Radio, Row, Spin, Table, Tooltip, Typography, Upload } from 'antd';
import { CheckCircleTwoTone, ClockCircleTwoTone, CloseOutlined, DeleteTwoTone, DotChartOutlined, DownloadOutlined, EditOutlined, ExportOutlined, InboxOutlined, InfoCircleTwoTone, PlusOutlined, SearchOutlined, StopTwoTone, UploadOutlined } from "@ant-design/icons";
import { connect } from 'react-redux';
import Highlighter from 'react-highlight-words';
import _ from 'lodash';

import { bulkCreateExperimentRequest, createExperimentRequest, deleteExperimentRequest, getAllExperiments, getSingleExperimentRequest, updateExperimentRequest } from '../features/experiments/experimentsActions';
import { createConfigurationRequest, getAllConfigurations } from '../features/configuration/configurationActions';
import { addExperimentToGroup, getAllGroups } from '../features/groups/groupsActions';
import { BACKEND_URL, EXPERIMENTS_URL } from '../shared/constants';

const EXPERIMENT_PAGE = 'experiment'
const GROUP_PAGE = 'groups'
const TREATMENT_PAGE = 'treatments'

function Experiment({
    experiments,
    groups,
    token,
    configuration,
    getAllExperiments,
    getAllGroups,
    updateExperimentRequest,
    createExperimentRequest,
    getAllConfigurations,
    createConfigurationRequest,
    addExperimentToGroup,
    bulkCreateExperimentRequest,
    getSingleExperimentRequest,
    deleteExperimentRequest
}) {
    const [state, setState] = useState({
        loading: true,
        createVisible: false,
        treatmentClicked: false,
        createPage: EXPERIMENT_PAGE,
        proteinFilesData: null,
        proteinFileNames: null,
        peptideFilesData: null,
        peptideFileNames: null,
        createLimmaData: null,
        createLimmaFileName: null,
        treatments: [],
        groupList: [],
        experiment: {
            name: null,
            experimentType: null,
            investigator: null,
            instrument: null,
            protocol: null,
            cellLine: null,
            treatedBy: null,
            processedBy: null,
            submittedBy: null,
        },
        editVisible: false,
        bulkVisible: false,
        bulkDataExperiment: null,
        bulkDatatreatments: [],
        bulkDataOutput: [],
        bulkCompoundTreatmentNames: [],
        bulkOutputNames: [],
        bulkLoading: false,
        editExperiment: null,
    });

    const [form] = Form.useForm();

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
            <div style={{padding: 8}}>
                <Input
                    ref={node => {
                        searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{width: 188, marginBottom: 8, display: 'block'}}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined/>}
                    size="small"
                    style={{width: 90, marginRight: 8}}
                >
                    Search
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{width: 90}}>
                    Reset
                </Button>
            </div>
        ),

        filterIcon: filtered => (
            <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>
        ),

        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()) : '',

        onFilterDropdownOpenChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.select());
            }
        },

        render: text => (
            (state.searchedColumn === dataIndex && text) ?
                <Highlighter
                    highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                    searchWords={[state.searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
                : text
        ),
    });

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            sorter: (a, b) => sorterHelper(a.name, b.name),
            ...getColumnSearchProps('name'),
        }, {
            title: 'Status',
            dataIndex: 'upload_status',
            key: 'status',
            fixed: 'left',

            render: (text, record) => {
                switch (record.upload_status) {
                    case 'CREATED':
                        return <Tooltip title="Created"><InfoCircleTwoTone twoToneColor="#ffa940"/></Tooltip>
                    case 'IN_PROGRESS':
                        return <Tooltip title="In Progress"><ClockCircleTwoTone twoToneColor="#fadb14"/></Tooltip>
                    case 'COMPLETE':
                        return <Tooltip title="Complete"><CheckCircleTwoTone twoToneColor="#52c41a"/></Tooltip>
                    case 'ERROR':
                        return <Tooltip title="Error"><StopTwoTone twoToneColor="#cf1322"/></Tooltip>
                    case 'DELETING':
                        return <Tooltip title="Deleting"><DeleteTwoTone twoToneColor="#531dab"/></Tooltip>
                    default:
                        return 'Unknown'
                }
            },

            filters: [{
                text: 'Created',
                value: 'created',
            }, {
                text: 'In Progress',
                value: 'in_progress',
            }, {
                text: 'Complete',
                value: 'complete',
            }, {
                text: 'Error',
                value: 'error',
            }, {
                text: 'Deleting',
                value: 'deleting',
            }],

            onFilter: (value, record) => {
                return record.upload_status === value
            },
        },
        {title: 'Experiment Type', dataIndex: 'experiment_type_display', key: 'experiment_type'},
        {title: 'Principal Investigator', dataIndex: 'principal_investigator_display', key: 'principal_investigator'},
        {title: 'Treated By', dataIndex: 'treated_by_display', key: 'treated_by'},
        {title: 'Protocol', dataIndex: 'protocol_display', key: 'protocol'},
        {title: 'Instrument', dataIndex: 'instrument_display', key: 'instrument'},
        {title: 'Submitted By', dataIndex: 'submitted_by_display', key: 'submitted_by'},
        {title: 'Processed By', dataIndex: 'processed_by_display', key: 'processed_by'},
        {title: 'Cell Line', dataIndex: 'cell_line_display', key: 'cell_line'},
        {
            title: 'Active',
            key: 'active',
            fixed: 'right',
            render: (text, record) => <Popconfirm title='Toggle active?'
                                                  onConfirm={() => onCheckChange(record)}><Checkbox
                checked={record.active}/></Popconfirm>,
            defaultFilteredValue: [true],
            filters: [
                {
                    text: 'Active',
                    value: true,
                },
                {
                    text: 'Inactive',
                    value: false,
                },
            ],
            onFilter: (value, record) => {
                return record.active === value
            },
        }, {
            title: 'Edit',
            key: 'edit',
            fixed: 'right',
            width: 100,
            render: (text, record) => (
                <Button
                    shape='circle'
                    size='small'
                    icon={<EditOutlined/>}
                    onClick={() => showEditModal(record)}
                />
            ),
        }, {
            title: 'Export',
            key: 'Export',
            fixed: 'right',
            width: 100,
            render: (text, record) => (
                <Button
                    shape='circle'
                    size='small'
                    icon={<ExportOutlined/>}
                    onClick={() => window.open(`${BACKEND_URL}${EXPERIMENTS_URL}${record.id}/export?token=${token}`, "_blank")}
                />
            ),
        }, {
            title: 'Delete',
            key: 'delete',
            fixed: 'right',
            width: 100,
            render: (text, record) => (
                <Popconfirm
                    title='Are you sure?'
                    onConfirm={() => deleteExperimentRequest(token, record.id)}
                >
                    <Button
                        style={{marginLeft: 10}}
                        icon={<CloseOutlined/>}
                        size='small'
                        type='danger'
                        shape='circle'
                    />
                </Popconfirm>
            )
        },
    ]

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setState({
            ...state,
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    const handleReset = clearFilters => {
        clearFilters();
        setState({
            ...state,
            searchText: ''
        });
    };

    const onCheckChange = (record) => {
        updateExperimentRequest(token, {id: record.id, active: !record.active})
    }

    const sorterHelper = (a, b) => {
        if (a) {
            return b ? a.value.localeCompare(b.value) : -1
        } else if (b) {
            return a ? b.value.localeCompare(a.value) : 1
        } else {
            return -1
        }
    }

    const showEditModal = (record) => {
        setState({
            ...state,
            loading: true,
            editVisible: false
        });

        getSingleExperimentRequest(token, record.id)
            .then(resp => {
                setState({
                    ...state,
                    loading: false,
                    editVisible: true,
                    editExperiment: record,
                    treatmentClicked: false,
                    createPage: EXPERIMENT_PAGE,
                    proteinFilesData: null,
                    proteinFileNames: null,
                    peptideFilesData: null,
                    peptideFileNames: null,
                    createLimmaData: null,
                    createLimmaFileName: null,

                    treatments: _.flatMap(resp.treatments, (treatment => {
                        return {
                            id: treatment.id,
                            treatmentName: treatment.name,
                            compoundName: treatment.compound.name,
                            smiles: treatment.compound.smiles,
                            time: treatment.time,
                            concentration: treatment.concentration,
                            notes: treatment.notes
                        };
                    })),

                    groupList: resp.groups,

                    experiment: {
                        name: resp.name,
                        experimentType: resp.experiment_type_display,
                        investigator: resp.principal_investigator_display,
                        instrument: resp.instrument_display,
                        protocol: resp.protocol_display,
                        cellLine: resp.cell_line_display,
                        treatedBy: resp.treated_by_display,
                        processedBy: resp.processed_by_display,
                        submittedBy: resp.submitted_by_display,
                    }
                });
            });
    };

    const getCurrentState = () => {
        const mostRecentData = persistCreateFormToState()
        let {experiment, treatments, groupList, proteinFilesData, proteinFileNames, peptideFilesData, peptideFileNames,createPage} = state

        if (createPage === EXPERIMENT_PAGE) {
            experiment = mostRecentData;
        } else if (createPage === TREATMENT_PAGE) {
            treatments = mostRecentData;
        }

        return {
            experiment,
            treatments,
            groupList,
            proteinFilesData,
            proteinFileNames,
            peptideFilesData,
            peptideFileNames,
            createPage,
        }
    }

    const showCreateModal = (e) => {
        handleCreateCancel(e, true)
    };

    const handleCreateOk = (e) => {
        persistCreateFormToState();

        const {editVisible, editExperiment} = state;

        setState({
            ...state,
            loading: true
        });

        let promises = []

        let configData = {
            "experiment_type_id": null,
            "principal_investigator_id": null,
            "instrument_id": null,
            "protocol_id": null,
            "cell_line_id": null,
            "treated_by_id": null,
            "processed_by_id": null,
            "submitted_by_id": null,
        }

        let {experiment, treatments, groupList, proteinFilesData, proteinFileNames, peptideFilesData, peptideFileNames} = getCurrentState();

        const {
            name,
            experimentType,
            treatedBy,
            processedBy,
            cellLine,
            investigator,
            instrument,
            submittedBy,
            protocol
        } = experiment

        let configFields = [
            ['experiment_type', experimentType],
            ['principal_investigator', investigator],
            ['instrument', instrument],
            ['protocol', protocol],
            ['cell_line', cellLine],
            ['treated_by', treatedBy],
            ['processed_by', processedBy],
            ['submitted_by', submittedBy]
        ];

        configFields.forEach(item => {
            const configObj = _.find(
                configuration.all,
                obj => obj.config_type === item[0] && obj.value === item[1]
            )
            if (configObj) {
                configData[`${item[0]}_id`] = configObj.id
            } else {
                promises.push(createConfigurationRequest(token, {value: item[1], config_type: item[0]}))
            }
        });

        let finalCreateData = {
            name,
            treatments: treatments,
            "protein_files_data": proteinFilesData,
            "protein_file_names": proteinFileNames,
            "peptide_files_data": peptideFilesData,
            "peptide_file_names": peptideFileNames
        };

        Promise.all(promises).then(allResp => {
            allResp.forEach(eachResp => {
                configData[`${eachResp.config_type}_id`] = eachResp.id
            })

            try {
                if (editVisible) {
                    finalCreateData['id'] = editExperiment.id;
                    finalCreateData['group'] = groupList;

                    delete finalCreateData['protein_files_data'];
                    delete finalCreateData['protein_file_names'];
                    delete finalCreateData['peptide_files_data'];
                    delete finalCreateData['peptide_file_names'];

                    updateExperimentRequest(token, _.merge(finalCreateData, configData)).then(() => {
                        setState({
                            ...state,
                            loading: false,
                            editVisible: false
                        });
                    });

                } else {
                    setState({
                        ...state,
                        loading: true,
                        editVisible: false
                    });

                    createExperimentRequest(token, _.merge(finalCreateData, configData)).then(() => {
                        handleCreateCancel(e);
                    });
                }

                form.resetFields();
            } catch (e) {
                handleCreateCancel(e);
            }
        })
    }

    const handleCreateCancel = (e, visible = false) => {
        // clear create state
        setState({
            ...state,
            editVisible: false,
            editExperiment: null,
            createVisible: visible,
            treatmentClicked: false,
            createPage: EXPERIMENT_PAGE,
            proteinFilesData: [],
            proteinFileNames: [],
            peptideFilesData: [],
            peptideFileNames: [],
            treatments: [],
            groupList: [],
            experiment: {
                name: null,
                experimentType: null,
                investigator: null,
                instrument: null,
                protocol: null,
                cellLine: null,
                treatedBy: null,
                processedBy: null,
                submittedBy: null,
            }
        });

        form.resetFields();
    };

    const onCreateChange = (e) => {
        form.validateFields().then(() => {
            persistCreateFormToState();

            if (e.target.value === TREATMENT_PAGE) {
                setState({
                    ...state,
                    createPage: e.target.value,
                    treatmentClicked: true
                });
            } else {
                setState({
                    ...state,
                    createPage: e.target.value
                });
            }
        });
    }

    const persistCreateFormToState = () => {
        const { createPage, treatments, editVisible, experiment } = state
        const prefix = editVisible ? 'edit' : 'create';

        switch (createPage) {
            case EXPERIMENT_PAGE:
                experiment.name = form.getFieldValue(`${prefix}_name`) || experiment.name;
                experiment.experimentType = form.getFieldValue(`${prefix}_experiment_type`) || experiment.experimentType;
                experiment.investigator = form.getFieldValue(`${prefix}_principal_investigator`) || experiment.investigator;
                experiment.instrument = form.getFieldValue(`${prefix}_instrument`) || experiment.instrument;
                experiment.protocol = form.getFieldValue(`${prefix}_protocol`) || experiment.protocol;
                experiment.cellLine = form.getFieldValue(`${prefix}_cell_line`) || experiment.cellLine;
                experiment.treatedBy = form.getFieldValue(`${prefix}_treated_by`) || experiment.treatedBy;
                experiment.processedBy = form.getFieldValue(`${prefix}_processed_by`) || experiment.processedBy;
                experiment.submittedBy = form.getFieldValue(`${prefix}_submitted_by`) || experiment.submittedBy;

                setState({
                    ...state,
                    experiment
                });

                return experiment;

            case TREATMENT_PAGE:
                _.range(treatments.length).forEach(i => {
                    treatments[i].treatmentName = form.getFieldValue(`treatment_${i}`) || treatments[i].treatmentName;
                    treatments[i].compoundName = form.getFieldValue(`compound_${i}`) || treatments[i].compoundName;
                    treatments[i].smiles = form.getFieldValue(`smiles_${i}`) || treatments[i].smiles;
                    treatments[i].time = form.getFieldValue(`time_${i}`) || treatments[i].time;
                    treatments[i].concentration = form.getFieldValue(`concentration_${i}`) || treatments[i].concentration;
                    treatments[i].notes = form.getFieldValue(`notes_${i}`) || treatments[i].notes;
                });

                setState({
                    ...state,
                    treatments
                });

                return treatments;

            case GROUP_PAGE:
                break

            default:
                console.log('pass')
        }
    }

    const createGroupListCheckboxEvent = (e, item) => {
        const {groupList} = state

        if (e.target.checked) {
            groupList.push(item.id)
        } else {
            _.remove(groupList, (n) => n === item.id)

        }
        setState({
            ...state,
            groupList
        })
    }

    const normFile = e => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    const showCreatePage = (prefix) => {
        const {createPage, editVisible} = state;

        switch (createPage) {
            case EXPERIMENT_PAGE:
                return <>
                    <Form.Item style={editVisible ? {display: 'none'} : {}}>
                        <strong>Protein files:</strong>
                        <Upload.Dragger
                            multiple
                            accept=".txt, .csv"
                            onRemove={file => {
                                setState({
                                    ...state,
                                    proteinFilesData: [],
                                    proteinFileNames: [],
                                    treatments: [],
                                })
                            }}
                            beforeUpload={file => {
                                const reader = new FileReader();

                                reader.onload = e => {
                                    let newCompoundTreatmentNames = [];

                                    // Parse compound information from heads in CSV
                                    newCompoundTreatmentNames.push(...e.target.result.split('\n', 2)[0].split(',')
                                        .filter((value, index, array) =>
                                            value.includes('Counts'))
                                        .map((value) =>
                                            value.split('.').slice(2).join('.').trim().replace('"', '')));

                                    let {treatments} = state;

                                    const headersAlreadyParsed = treatments.map((compoundTreatment) => compoundTreatment.header);

                                    newCompoundTreatmentNames = newCompoundTreatmentNames.filter((value, index, array) =>
                                        array.indexOf(value) === index && headersAlreadyParsed.indexOf(value) === -1);

                                    newCompoundTreatmentNames.forEach((newCompoundTreatmentName) => treatments.push({
                                        header: newCompoundTreatmentName,
                                        treatmentName: newCompoundTreatmentName,
                                        compoundName: newCompoundTreatmentName,
                                    }));

                                    const {experiment, proteinFilesData, proteinFileNames} = state;

                                    proteinFilesData.push(btoa(e.target.result));
                                    proteinFileNames.push(file.name);

                                    experiment.name = `${proteinFileNames[0].split('_')[0]}_${proteinFileNames[0].split('_')[1]}`;

                                    form.setFieldValue(`${prefix}_name`, experiment.name);

                                    setState({
                                        ...state,
                                        proteinFilesData,
                                        proteinFileNames,
                                        treatments,
                                        experiment,
                                    });
                                };
                                reader.readAsText(file);

                                return false;
                            }}
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined/>
                            </p>
                            <p className="ant-upload-text">Click here or drag files to this area to upload</p>
                            <p className="ant-upload-hint">Supports multiple files.</p>
                        </Upload.Dragger>
                    </Form.Item>
                    <Form.Item style={editVisible ? {display: 'none'} : {}}>
                        <strong>Peptide files:</strong>
                        <Upload.Dragger
                            multiple
                            accept=".txt, .csv"
                            onRemove={file => {
                                const {experiment} = state;
                                experiment.peptideData = null;
                                setState({
                                    ...state,
                                    experiment
                                });
                            }}
                            beforeUpload={file => {
                                const reader = new FileReader();

                                reader.onload = e => {
                                    const {peptideFilesData, peptideFileNames} = state;

                                    peptideFilesData.push(btoa(e.target.result));
                                    peptideFileNames.push(file.name);

                                    setState({
                                        ...state,
                                        peptideFilesData,
                                        peptideFileNames
                                    });
                                };

                                reader.readAsText(file);

                                return false;
                            }}
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined/>
                            </p>
                            <p className="ant-upload-text">Click here or drag files to this area to upload</p>
                            <p className="ant-upload-hint">Supports multiple files.</p>
                        </Upload.Dragger>
                    </Form.Item>
                    <Form.Item name={`${prefix}_name`} label={'Experiment name'} labelCol={{span: 8}} wrapperCol={{span: 14}} >
                        <AutoComplete
                            options={filterConfig('name')}
                            placeholder={`Enter experiment name`}
                            filterOption={(inputValue, option) => {
                                return option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                            }}
                            defaultValue={state.experiment.name}
                        />
                    </Form.Item>

                    {createFormItem('experiment type', 'experiment_type', prefix, 'experimentType')}
                    {createFormItem('investigator', 'principal_investigator', prefix, 'investigator')}
                    {createFormItem('instrument', 'instrument', prefix, 'instrument')}
                    {createFormItem('protocol', 'protocol', prefix, 'protocol')}
                    {createFormItem('cell line', 'cell_line', prefix, 'cellLine')}
                    {createFormItem('treated by', 'treated_by', prefix, 'treatedBy')}
                    {createFormItem('processed by', 'processed_by', prefix, 'processedBy')}
                    {createFormItem('submitted by', 'submitted_by', prefix, 'submittedBy')}
                </>
            case TREATMENT_PAGE:
                const treatmentRows = state.treatments.map((compoundTreatment, i) => <>
                    <Row gutter={2} key={i}>
                        <Col span={3}>
                            <Form.Item name={`treatment_${i}`}>
                                <Input
                                    placeholder='Input Treatement'
                                    defaultValue={compoundTreatment.treatmentName}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item name={`compound_${i}`}>
                                <Input
                                    disabled={compoundTreatment.compoundName === 'DMSO'}
                                    placeholder="Required"
                                    defaultValue={compoundTreatment.compoundName}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={7}>
                            <Form.Item name={`smiles_${i}`}>
                                <Input
                                    placeholder="Enter SMILES"
                                    defaultValue={compoundTreatment.smiles}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item name={`time_${i}`}>
                                <Input
                                    placeholder="Enter mins"
                                    defaultValue={compoundTreatment.time}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name={`concentration_${i}`}>
                                <Input
                                    placeholder="Enter number as number with up to four digits after decimal point"
                                    defaultValue={compoundTreatment.concentration}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name={`notes_${i}`}>
                                <Input
                                    placeholder="Optional"
                                    defaultValue={compoundTreatment.notes}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </>);

                return <>
                    <Row gutter={8} key={'title'}>
                        <Col className='uploadLabel' span={3}>
                            Treatment
                        </Col>
                        <Col className='uploadLabel' span={3}>
                            Compound
                        </Col>
                        <Col className='uploadLabel' span={7}>
                            SMILES
                        </Col>
                        <Col className='uploadLabel' span={3}>
                            Time [min]
                        </Col>
                        <Col className='uploadLabel' span={4}>
                            Concentration [µM]
                        </Col>
                        <Col className='uploadLabel' span={4}>
                            Notes
                        </Col>
                    </Row>

                    {treatmentRows}
                </>

            case GROUP_PAGE:
                return <>
                    <List
                        bordered={true}
                        pagination={true}
                        dataSource={_.map(groups.active, (data => data))}
                        renderItem={item => (
                            <List.Item key={item.id}>
                                <List.Item.Meta
                                    avatar={<Checkbox
                                        checked={state.groupList.includes(item.id)}
                                        onChange={(e) => createGroupListCheckboxEvent(e, item)}
                                    />}
                                    title={item.name}
                                />
                            </List.Item>
                        )}
                    >
                    </List>
                </>

            default:
                return <p>Error: Frontend Issue, Contact Admin</p>

        }
    }

    const filterConfig = (filterType) => {
        const unfilteredValues = _.filter(
            configuration.active,
            config_item => config_item.config_type === filterType
        );

        return unfilteredValues.sort(sorterHelper);
    }

    const createFormItem = (itemLabel, itemType = null, labelType = 'edit', stateName = null, disabled = false) => {
        const { experiment } = state;
        const configObjectName = itemType ? itemType : itemLabel;
        const formItemName = `${labelType}_${itemType}`;
        const stateValue = experiment[stateName];

        return <Form.Item name={formItemName} label={_.startCase(itemLabel)} labelCol={{span: 8}} wrapperCol={{span: 14}} >
            <AutoComplete
                options={filterConfig(configObjectName)}
                placeholder={`Enter ${itemLabel}`}
                filterOption={(inputValue, option) => {
                    return option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }}
                defaultValue={stateValue}
                disabled={disabled}
            />
        </Form.Item>
    }

    const hasErrors = (fieldsError) => {
        return Object.keys(fieldsError).some(field => fieldsError[field]);
    }

    const showBulkPage = () => {
        const {bulkLoading} = state

        if (bulkLoading) {
            return <Spin/>
        }

        return <>
            <Form.Item label='Experiment CSV'>
                <Upload.Dragger
                    accept=".csv"
                    multiple={false}
                    onChange={(e) => {
                        if (e.fileList.length > 1) {
                            e.fileList.shift()
                        }
                    }}
                    beforeUpload={file => {
                        return false;
                    }}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined/>
                    </p>
                    <p className="ant-upload-text">Click or drag one file to this area to upload</p>
                    <p className="ant-upload-hint">Add one CSV that contains mappings for all experiment fields.</p>
                </Upload.Dragger>
            </Form.Item>
            <Form.Item label='Compound Treatment CSVs'>
                <Upload.Dragger
                    accept=".csv"
                    multiple={true}
                    beforeUpload={file => {
                        return false;
                    }}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined/>
                    </p>
                    <p className="ant-upload-text">Click or drag multiple files to this area to upload</p>
                    <p className="ant-upload-hint">Add many CSVs, one per experiment, that contain metadata for each
                        compound treatment in each experiment.</p>
                </Upload.Dragger>
            </Form.Item>
            <Form.Item label='MS Output files'>
                <Upload.Dragger
                    accept=".txt, .csv"
                    multiple={true}
                    beforeUpload={file => {
                        return false;
                    }}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined/>
                    </p>
                    <p className="ant-upload-text">Click or drag multiple files to this area to upload</p>
                    <p className="ant-upload-hint">Add many CSVs or text files, one per experiment, that contain
                        mass spectronomy output for each experiment.</p>
                </Upload.Dragger>
            </Form.Item>
        </>
    }

    const handleBulkCancel = () => {
        setState({
            ...state,
            bulkVisible: false,
            bulkDataOutput: [],
            bulkDataExperiment: null,
            bulkDataCompoundTreatment: [],
            bulkCompoundTreatmentNames: [],
            bulkOutputNames: []
        })
    }

    const handleBulkOk = () => {
        setState({
            ...state,
            bulkLoading: true
        });

        const experimentReader = new FileReader();

        experimentReader.onload = e => {
            const bulkDataExperiment = btoa(e.target.result)
            setState({
                ...state,
                bulkDataExperiment
            })
        };

        const compoundTreatmentLoad = e => {
            const {bulkDataCompoundTreatment} = state
            const newBulkDataCompoundTreatment = btoa(e.target.result)
            bulkDataCompoundTreatment.push(newBulkDataCompoundTreatment)
            setState({
                ...state,
                bulkDataCompoundTreatment
            })
        };

        const outputLoad = e => {
            const {bulkDataOutput} = state
            const newBulkDataOutput = btoa(e.target.result)

            bulkDataOutput.push(newBulkDataOutput);

            setState({
                ...state,
                bulkDataOutput
            });
        };

        form.getFieldsValue(['bulkDraggerExperiment']).bulkDraggerExperiment.forEach(inputFile => {
            experimentReader.readAsText(inputFile.originFileObj);
        });

        form.getFieldsValue(['bulkDraggerCompoundTreatment']).bulkDraggerCompoundTreatment.forEach(inputFile => {
            const compoundTreatmentReader = new FileReader();
            compoundTreatmentReader.onload = compoundTreatmentLoad
            compoundTreatmentReader.onloadend = e => {
                const {bulkCompoundTreatmentNames} = state

                bulkCompoundTreatmentNames.push(inputFile.name)

                setState({
                    ...state,
                    bulkCompoundTreatmentNames
                });
            }
            compoundTreatmentReader.readAsText(inputFile.originFileObj);
        });

        form.getFieldsValue(['bulkDraggerOutput']).bulkDraggerOutput.forEach(inputFile => {
            const outputReader = new FileReader();
            outputReader.onload = outputLoad
            outputReader.onloadend = e => {
                const { bulkOutputNames } = state;

                bulkOutputNames.push(inputFile.name);

                setState({
                    ...state,
                    bulkOutputNames
                });
            }
            outputReader.readAsText(inputFile.originFileObj);
        });

        setTimeout(() => {
            const {
                bulkDataExperiment,
                bulkDataOutput,
                bulkDataCompoundTreatment,
                bulkCompoundTreatmentNames,
                bulkOutputNames
            } = state;
            bulkCreateExperimentRequest(token, {
                experiment_data: bulkDataExperiment,
                compound_treatment_data: bulkDataCompoundTreatment,
                output_data: bulkDataOutput,
                compound_treatment_names: bulkCompoundTreatmentNames,
                output_names: bulkOutputNames
            }).then(resp => {
                setState({
                    ...state,
                    bulkLoading: false,
                    bulkVisible: false,
                    bulkDataOutput: [],
                    bulkDataExperiment: null,
                    bulkDataCompoundTreatment: [],
                    bulkCompoundTreatmentNames: [],
                    bulkOutputNames: []
                });
            })
        }, 500 * form.getFieldsValue(['bulkDraggerOutput']).bulkDraggerOutput.length);
    }

    const {Title} = Typography
    const {
        createVisible,
        loading,
        editVisible,
        editExperiment,
        proteinFilesData,
        treatmentClicked,
        bulkVisible
    } = state;

    useEffect(() => {
        let promises = [
            getAllExperiments(token),
            getAllGroups(token),
            getAllConfigurations(token),
        ];

        Promise.all(promises).then(value => setState({
            ...state,
            loading: false
        }));
    }, [getAllExperiments, getAllGroups, getAllConfigurations, token]);

    return <>
        <Typography>
            <Title level={3}>
                <DotChartOutlined style={{
                    marginRight: '0.25em'
                }}/>
                Experiments
                <Tooltip title='Upload'>
                    <Button size={'small'}
                            shape={'circle'}
                            icon={<PlusOutlined/>}
                            onClick={showCreateModal}
                            style={{
                                verticalAlign: 'top',
                                margin: '0.25em'
                            }}
                    />
                </Tooltip>
            </Title>
        </Typography>
        <Divider />
        { loading ? <Spin /> :
            <Table columns={columns}
                   rowKey='id'
                   dataSource={_.map(experiments.all, (data => data))}
                   scroll={{x: 1850}}
            />
        }
        <Modal width={900}
               title="Create Experiment"
               open={createVisible}
               onOk={handleCreateOk}
               onCancel={handleCreateCancel}
               destroyOnClose={true}
               okButtonProps={{disabled: !treatmentClicked, icon: <UploadOutlined />}}
               cancelButtonProps={{icon: <CloseOutlined />}}
               okText={'Submit'}
        >
            <Radio.Group className='createRadioGroup' onChange={onCreateChange} defaultValue={EXPERIMENT_PAGE}>
                <Radio.Button value={EXPERIMENT_PAGE}>Experiment</Radio.Button>
                <Radio.Button value={TREATMENT_PAGE} disabled={!Boolean(proteinFilesData)}>Treatments</Radio.Button>
                <Radio.Button value={GROUP_PAGE}>Groups</Radio.Button>
            </Radio.Group>
            <Form form={form}>
                {showCreatePage('create')}
            </Form>
        </Modal>
        <Modal width={775}
               title={`Edit ${editExperiment ? editExperiment.name : 'experiment'}`}
               open={editVisible}
               onOk={handleCreateOk}
               onCancel={handleCreateCancel}
               destroyOnClose={true}
               okButtonProps={{icon: <UploadOutlined/>}}
               okText={'Submit'}
        >
            <Radio.Group className='createRadioGroup' onChange={onCreateChange} defaultValue={EXPERIMENT_PAGE}>
                <Radio.Button value={EXPERIMENT_PAGE}>Experiment</Radio.Button>
                <Radio.Button value={TREATMENT_PAGE}>Treatments</Radio.Button>
                <Radio.Button value={GROUP_PAGE}>Groups</Radio.Button>
            </Radio.Group>
            <Form form={form}>
                {showCreatePage('edit')}
            </Form>
        </Modal>
        <Modal width={775}
               title={'Bulk Upload'}
               open={bulkVisible}
               onOk={() => handleBulkOk()}
               onCancel={() => handleBulkCancel()}
               destroyOnClose={true}
               okButtonProps={{
                   disabled: !(form.getFieldsValue(['bulkDraggerOutput']).bulkDraggerOutput && form.getFieldsValue(['bulkDraggerCompoundTreatment']).bulkDraggerCompoundTreatment && form.getFieldsValue(['bulkDraggerExperiment']).bulkDraggerExperiment),
                   icon: 'upload'
               }}
               okText={'Submit'}
        >
            <Button
                icon={<DownloadOutlined/>}
                onClick={() => {
                    window.open(`${BACKEND_URL}/experiments_template?token=${token}`, "_blank")
                }}
            >
                Download Templates
            </Button>
            <Form form={form}>
                {showBulkPage()}
            </Form>
        </Modal>
        <Form form={createVisible || editVisible || bulkVisible ? undefined : form} />
    </>
}

const mapStateToProps = ({experiments, groups, configuration, token}) => {
    return {
        experiments,
        groups,
        configuration,
        token: token.token
    }
}

export default connect(mapStateToProps, {
    getAllExperiments,
    getAllGroups,
    updateExperimentRequest,
    createExperimentRequest,
    getAllConfigurations,
    createConfigurationRequest,
    addExperimentToGroup,
    bulkCreateExperimentRequest,
    getSingleExperimentRequest,
    deleteExperimentRequest,
})(Experiment)