import {
    SET_EXPERIMENTS,
    UPDATE_EXPERIMENT,
    SET_ACTIVE_EXPERIMENTS,
    UPDATE_ACTIVE_EXPERIMENT,
    REMOVE_ACTIVE_EXPERIMENT,
    SET_SEARCHED_EXPERIMENT,
    GET_ALL_TREATMENTS,
    GET_EXPERIMENT_COMPOUNDS,
    GET_ALL_PROTEINS,
} from './experimentsConstants'
import { produce } from 'immer'

const INITIAL_STATE = {}

export default produce ((draft = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_EXPERIMENTS:
            const { experiments } = action
            draft['all'] =  experiments
            break
        
        case UPDATE_EXPERIMENT:
            const { experimentId, data } = action
            draft['all'][experimentId] = data
            break

        case SET_ACTIVE_EXPERIMENTS:
            const { activeExperiments } = action
            draft['active'] =  activeExperiments
            break
        
        case UPDATE_ACTIVE_EXPERIMENT:
            const { activeExperimentId, activeData} = action
            draft['active'][activeExperimentId] = activeData
            break

        case REMOVE_ACTIVE_EXPERIMENT:
            const { removeExperimentId } = action
            delete draft['active'][removeExperimentId]
            break
        
        case SET_SEARCHED_EXPERIMENT:
            const { searchedExperiment } = action
            draft['searchedExperiment'] = searchedExperiment
            break

        case GET_ALL_TREATMENTS:
            const { allTreatments } = action
            draft['allTreatments'] = allTreatments
            break

        case GET_EXPERIMENT_COMPOUNDS:
            const { experimentCompounds } = action
            draft['experimentCompounds'] = experimentCompounds
            break
    
        case GET_ALL_PROTEINS:
            const { allProteins } = action
            draft['allProteins'] = allProteins
            break

        default:
            break
        }

    return draft
})