import React, { Component } from 'react';
import Plot from 'react-plotly.js';
import { Spin } from 'antd';
import { connect } from 'react-redux';
import { getHeatmapData } from   '../features/experiments/experimentsActions';
import { setHeatmapMax, setHeatmapMin } from '../features/results/resultsActions';
import AwesomeDebouncePromise from "awesome-debounce-promise";

class Heatmap extends Component {
    state = {
        loading: true,
        xVals: [],
        yVals: [],
        zVals: [],
        log10PValues: []
    }

    debouncedGetData = AwesomeDebouncePromise((token, experimentId, minCount, minReplicates, foldChangeThreshold, maxLog10PValue, minUniquePeptides, volcanoType) => {
        this.setState({ loading: true });

        return this.props.getHeatmapData(token, experimentId, minCount, minReplicates, foldChangeThreshold, maxLog10PValue, minUniquePeptides, volcanoType)
            .then(resp => {
                if (resp) {
                    this.setState({
                        xVals: resp['treatment_names'],
                        yVals: resp['gene_symbols'],
                        zVals: resp['z_vals'],
                        log10PValues: resp['log10_p_values'],
                        loading: false,
                    });
                }
                return resp;
        });
    }, 500);

    componentDidMount() {
        this.debouncedGetData(
            this.props.token.token,
            this.props.experimentId,
            this.props.minCount,
            this.props.minReplicates,
            this.props.foldChangeThreshold,
            this.props.maxLog10PValue,
            this.props.minUniquePeptides,
            this.props.volcanoType
        ).then(resp => {
            if (resp) {
                this.props.setHeatmapMax(this.props.heatmapMax);
                this.props.setHeatmapMin(this.props.heatmapMin);
            }
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.volcanoType !== this.props.volcanoType ||
            prevProps.minCount !== this.props.minCount ||
            prevProps.minReplicates !== this.props.minReplicates ||
            prevProps.foldChangeThreshold !== this.props.foldChangeThreshold ||
            prevProps.maxLog10PValue !== this.props.maxLog10PValue ||
            prevProps.minUniquePeptides !== this.props.minUniquePeptides
        ) {
            this.debouncedGetData(
                this.props.token.token,
                this.props.experimentId,
                this.props.minCount,
                this.props.minReplicates,
                this.props.foldChangeThreshold,
                this.props.maxLog10PValue,
                this.props.minUniquePeptides,
                this.props.volcanoType
            );
        }
    }

    render() {
        const { xVals, yVals, zVals, loading, log10PValues } = this.state
        const { colorScheme, results } = this.props

        if ( loading ) {
            return <Spin style={{marginTop: 10}} />
        }

        const data = [{
            type: 'heatmap',
            autocolorscale: false,
            colorscale: colorScheme,
            x: xVals,
            y: yVals,
            z: zVals,
            hoverongaps: false,
            zauto: false,
            zmax: results.heatmapMax,
            zmin: results.heatmapMin,
            name: '',
            customdata: log10PValues,
            hovertemplate: `
<b>Log<sub>2</sub>FC:</b> %{z}<br />
<b>Log<sub>10</sub>P-Value:</b> %{customdata}<br />
<b>Treatment:</b> %{x}<br />
<b>Gene:</b> %{y}<br />`
            },
        ]
        
        return <>
              <Plot
                config={{
                  displaylogo: false,
                  responsive: true,
                  toImageButtonOptions: {
                    format: 'svg',
                    filename:'hits_heatmap'
                  }
                }}
                data={data}
                layout={{
                  margin: {t:0,r:0,l:20},
                  hovermode: 'closest',
                  autosize: true,
                  showlegend: false,
                  xaxis: {
                    title: 'Treatments',
                  },
                  yaxis: {
                    title: {
                      text: 'Hits',
                      standoff: 10,
                    },
                    automargin: true,

                  }
                }}
                useResizeHandler={true}
                style={{width: "100%"}}
              />
        </>
    }
}

const mapStateToProps = ( { experiments, results }) => {
  return { 
    experiments,
    results
  }
}
export default connect (mapStateToProps, {
  getHeatmapData,
  setHeatmapMax, 
  setHeatmapMin,
}) (Heatmap)