export const SET_HEATMAP_MAX = 'SET_HEATMAP_MAX'
export const SET_HEATMAP_MIN = 'SET_HEATMAP_MIN'

export const SET_QUERY_TYPE = 'SET_QUERY_TYPE'
export const SET_VOLCANO_X = 'SET_VOLCANO_X'
export const SET_VOLCANO_Y_MIN = 'SET_VOLCANO_Y_MIN'
export const SET_VOLCANO_Y_MAX = 'SET_VOLCANO_Y_MAX'
export const SET_MIN_COUNT = 'SET_MIN_COUNT'
export const SET_MIN_REPLICATES = 'SET_MIN_REPLICATES'

export const SET_VOLCANO_P = 'SET_VOLCANO_P'
export const SET_VOLCANO_FC = 'SET_VOLCANO_FC'
export const SET_VOLCANO_UNIQUE = 'SET_VOLCANO_UNIQUE'
export const SET_VOLCANO_TYPE = 'SET_VOLCANO_TYPE'

export const CLICKED_PROTEIN = 'CLICKED_PROTEIN'
export const LOADING_PROTEIN = 'LOADING_PROTEIN'

export const ADD_CUSTOM_PROTEIN = 'ADD_CUSTOM_PROTEIN'
export const ADD_CUSTOM_COMPOUND = 'ADD_CUSTOM_COMPOUND'

export const REMOVE_CUSTOM_PROTEIN = 'REMOVE_CUSTOM_PROTEIN'
export const REMOVE_CUSTOM_COMPOUND = 'REMOVE_CUSTOM_COMPOUND'

export const SET_COMPOUND_SEARCH = 'SET_COMPOUND_SEARCH'
export const SET_PROTEIN_SEARCH = 'SET_PROTEIN_SEARCH'

export const SET_CUSTOM_HEATMAP = 'SET_CUSTOM_HEATMAP'
export const SET_PEPTIDE_BOX_PLOT = 'SET_PEPTIDE_BOX_PLOT'

export const SET_SHOW_LABEL = 'SET_SHOW_LABEL'

export const CLEAR_CUSTOM_PROTEIN = 'CLEAR_CUSTOM_PROTEIN'
export const CLEAR_CUSTOM_COMPOUND = 'CLEAR_CUSTOM_COMPOUND'