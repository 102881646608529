import {AutoComplete, Button, Col, Divider, Input, notification, Radio, Row, Spin, Tooltip, Typography} from "antd";
import {CloseCircleTwoTone, ExportOutlined, HeatMapOutlined, SearchOutlined} from "@ant-design/icons";
import {
    BACKEND_URL,
    COMPOUND_QUERY,
    DEFAULT_QUERY_TYPE,
    ERROR_COLOR,
    EXPORT_EXPERIMENTS_URL,
    PROTEIN_QUERY,
    PROTEIN_SEARCH_MIN,
    TREATMENT_QUERY
} from "../constants";
import _ from "lodash";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router";
import {connect} from "react-redux";
import {getAllProteins, getAllTreatments, getResultDataRequest} from "../../features/experiments/experimentsActions";
import {sorterHelper} from "../utilities/SorterHelper";
import {addCustomCompound, addCustomProtein} from "../../features/results/resultsActions";

function SearchBarWrapper({ children, initialSearchValue, hits, experiments, token, addCustomProtein, addCustomCompound, getAllTreatments, getAllProteins }) {
    const { Title } = Typography;

    const navigate = useNavigate();
    const { dataType, id } = useParams();

    const [searchValue, setSearchValue] = useState('');
    const [loading, setLoading] = useState(true);
    const [queryType, setQueryType] = useState(DEFAULT_QUERY_TYPE);

    function filterSearch() {
        if (queryType === TREATMENT_QUERY) {
            return _.map(experiments.allTreatments, (compound) => {
                return { value: compound.name };
            }).sort(sorterHelper);
        } else if (searchValue && searchValue.length >= PROTEIN_SEARCH_MIN) {
            return _.map(experiments.allProteins, (record) => {
                return { value: `${record['gene_symbol']} (${record['name']})` };
            }).sort(sorterHelper);
        }
    }

    function handleKeyDown (e) {
        if (e.key === 'Enter') {
            handleSearch();
        }
    }

    function handleSearch () {
        const isTreatmentQuery = queryType === TREATMENT_QUERY;
        const queryTarget = _.filter(isTreatmentQuery ? experiments.allTreatments : experiments.allProteins,
            record => {
                let regExp = /\(([^)]+)\)/;
                if (isTreatmentQuery) {
                    return record.name === searchValue;
                } else {
                    let matches = regExp.exec(searchValue);
                    return Boolean(matches) && record.name === matches[1];
                }
            }
        );

        if (Boolean(queryTarget)) {
            const queryObject = queryTarget[0]

            if (Boolean(queryObject)) {
                const targetId = queryObject[isTreatmentQuery ? 'treatment_id' : 'accession_id'];
                const url = `/plots/${queryType}/${targetId}`;
                navigate(url);
            }
        }
    }

    useEffect(() => {
        if (initialSearchValue === undefined || searchValue === initialSearchValue) {
            return;
        }

        setSearchValue(initialSearchValue);
    }, [
        initialSearchValue,
        dataType,
        id,
    ]);

    useEffect(() => {
        if (dataType === undefined || queryType === dataType) {
            return;
        }

        setQueryType(dataType);
    }, [
        dataType,
    ]);

    useEffect(() => {
        let promises = [
            getAllTreatments(token),
            getAllProteins(token),
        ];

        Promise.all(promises)
            .then(values => setLoading(false));
    }, []);

    if (loading) {
        return <Spin />
    }

    let searchTitle = 'Search';
    if (initialSearchValue !== undefined) {
        searchTitle += `: ${initialSearchValue}`;
    }

    let searchBarPlaceholderText = `Enter ${queryType ? queryType : TREATMENT_QUERY}`;

    if (queryType === PROTEIN_QUERY) {
        searchBarPlaceholderText += ' - minimum ' + String(PROTEIN_SEARCH_MIN) + ' characters';
    }

    return <>
        <Typography>
            <Title level={3}>
                <SearchOutlined/> {searchTitle}
            </Title>
        </Typography>
        <Divider/>
        <Row gutter={8}>
            <Col span={6}>
                <Radio.Group size='large' value={queryType} onChange={(value) => setQueryType(value.target.value)}>
                    <Radio.Button value={TREATMENT_QUERY}>Compound</Radio.Button>
                    <Radio.Button value={PROTEIN_QUERY}>Protein</Radio.Button>
                </Radio.Group>
            </Col>
            <Col span={12}>
                <AutoComplete
                    style={{
                        width: '100%',
                        size: 'large'
                    }}
                    value={searchValue}
                    onChange={setSearchValue}
                    options={filterSearch()}
                    placeholder={searchBarPlaceholderText}
                    filterOption={(inputValue, option) => {
                        return option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    }}
                >
                    <Input
                        onKeyDown={handleKeyDown}
                        value={searchValue}
                        suffix={
                            <>
                                <CloseCircleTwoTone
                                    style={!Boolean(searchValue) ? {display: 'none'} : {marginRight: 5}}
                                    onClick={() => setSearchValue('')}
                                />
                                <Tooltip title='Search'>
                                    <SearchOutlined
                                        onClick={handleSearch}
                                        style={!Boolean(searchValue) ? {display: 'none'} : {}}
                                    />
                                </Tooltip>
                            </>
                        }
                    />
                </AutoComplete>
            </Col>
            <Col span={6}>
                <Button disabled={!Boolean(experiments.searchedExperiment)} style={{float: 'right'}} size='large'
                        icon={<ExportOutlined />}
                        onClick={() => {
                            let targetKey;
                            if (TREATMENT_QUERY in experiments.searchedExperiment) {
                                targetKey = TREATMENT_QUERY;
                            } else if (COMPOUND_QUERY in experiments.searchedExperiment) {
                                targetKey = COMPOUND_QUERY;
                            } else {
                                targetKey = PROTEIN_QUERY;
                            }

                            const target = experiments.searchedExperiment[targetKey];

                            const exportType = filterLabel ? PROTEIN_QUERY : TREATMENT_QUERY
                            const compoundId = !filterLabel ? target.compound_id : 1

                            window.open(`${BACKEND_URL}${EXPORT_EXPERIMENTS_URL}${exportType}/${target.id}/${queryType !== PROTEIN_QUERY ? experiments.searchedExperiment.meta.id : ''}?token=${token}&id=${compoundId}`, "_blank")
                        }}>
                    Export
                </Button>
                <Button disabled={!Boolean(experiments.searchedExperiment) || experiments.searchedExperiment.meta.filter_label} style={{float: 'right'}}
                        size='large' icon={<HeatMapOutlined />}
                        onClick={() => {
                            const {meta} = experiments.searchedExperiment;

                            hits.forEach(record => {
                                    addCustomProtein({
                                        id: `${record.accession_name} (${record.gene_symbol})`,
                                        key: String(record.accession_id)
                                    })
                                    addCustomCompound({
                                        id: `${record.treatment_name} (${meta.name})`,
                                        key: `${record.treatment_id}-${meta.id}`
                                    })
                                }
                            )
                            notification.success({
                                placement: 'bottomRight',
                                message: 'Success',
                                description: 'Successfully added to custom heatmap!',
                                style: {backgroundColor: ERROR_COLOR}
                            })
                        }
                        }>
                    Add to Heatmap
                </Button>
            </Col>
        </Row>
        {children}
    </>
}

const mapStateToProps = ({ experiments, results, token }) => {
    return {
        experiments,
        results,
        token: token.token
    }
}

export default connect(mapStateToProps, {
    getResultDataRequest,
    getAllTreatments,
    getAllProteins,
    addCustomProtein,
    addCustomCompound,
})(SearchBarWrapper);