import React, {Component} from 'react'
import {Modal, Row, Select, Spin, Typography,} from 'antd'
import GoogleButton from 'react-google-button'
import {connect} from 'react-redux'

import {LOGIN_ENDPOINT, TREATMENT_QUERY,} from '../shared/constants';
import {SearchBarWrapper} from '../shared/components'
import {setSearchedExperiment} from '../features/experiments/experimentsActions'
import {
    addCustomCompound,
    setClickedAsync,
    setLoading,
} from '../features/results/resultsActions'
import protein from '../assets/protein.png'
import compound from '../assets/compound.png'
import {toggleLoginModal} from "../features/token/tokenActions";

const {Option} = Select

class Home extends Component {
    constructor(props) {
        super(props)

        const {Text} = Typography;

        this.queryId = -1;

        this.state = {
            visible: false,
            loading: false,
        }
    }

    componentDidMount() {
        const { setSearchedExperiment } = this.props
        setSearchedExperiment(undefined);
    }

    handleOk = e => {
        this.props.toggleLoginModal();
        window.location.href = LOGIN_ENDPOINT;
    };

    handleCancel = e => {
        this.props.toggleLoginModal()
    };

    render() {
        const {Title, Paragraph, Text} = Typography;
        const { experiments, results } = this.props;
        const {searchedExperiment} = experiments;

        if (results.loading) {
            return <Spin/>
        }

        if (this.props.token) {
            return <>
                <SearchBarWrapper>
                    <Row gutter={8} style={!Boolean(searchedExperiment) ? {marginTop: 15, display: 'block'} : {display: 'none'}}>
                        {this.props.results.queryType === TREATMENT_QUERY
                            ? <Text>Search by <Text strong>Compound</Text> to find whole cell proteomics data for your
                                compound of interest.</Text>
                            : <Text>Search by <Text strong>Protein</Text> gene symbol or Uniprot accession number to find
                                experiments where your protein of interest was measured.</Text>
                        }
                        <Title level={4}>Welcome to the Fischer Lab’s Proteomics Database</Title>
                        <Paragraph>
                            The goal of this proteomics webtool is to provide a growing open-access resource for
                            quantitative global proteome responses from cell lines treated with various compounds and novel
                            chemical probes.
                        </Paragraph>
                        <Paragraph>
                            <Text strong>This webtool can be used for the:</Text>
                        </Paragraph>
                        <Paragraph>
                            &#8226; Identification of PROTEIN abundance changes in response to different compound treatments
                        </Paragraph>
                        <Paragraph>
                            <img className='protein-img' src={protein} alt='Demo scatter 1'/>
                        </Paragraph>
                        <Paragraph>
                            &#8226; Identification of COMPOUNDS inducing abundance changes of particular proteins
                        </Paragraph>
                        <Paragraph>
                            <img className='compound-img' src={compound} alt='Demo scatter 2'/>
                        </Paragraph>
                    </Row>
                </SearchBarWrapper>
            </>

        } else {
            return <>
                <Typography>
                    <Title level={4}>Welcome to the Fischer Lab’s Proteomics Database</Title>
                    <Paragraph>
                        The goal of this proteomics webtool is to provide a growing open-access resource for
                        quantitative global proteome responses from cell lines treated with various compounds and novel
                        chemical probes.
                    </Paragraph>
                    <Paragraph>
                        To access the database please signup using a gmail address as we are currently only supporting
                        gmail as means of authentication.
                        After registration it will take up to 72 hrs for us to approve your request, and once approved
                        you will have full access to the database via this webtool and also via a REST API.
                    </Paragraph>
                    <Paragraph>
                        Further instructions can be found on our lab website.
                    </Paragraph>
                    <Title level={4}>Fischer Lab Proteomics Database Bulletin Board</Title>
                    <Paragraph>
                        To subscribe to the Fischer lab’s proteomics database bulletin board for updates on future data
                        release please email: {<a
                        href={'mailto:fischerlab-proteomics+subscribe@crystal.harvard.edu'}>fischerlab-proteomics+subscribe@crystal.harvard.edu</a>}
                    </Paragraph>
                    <Paragraph>
                        Archived messages can be browsed and searched at the list homepage: <a
                        href={'https://groups.google.com/a/crystal.harvard.edu/g/fischerlab-proteomics'}>https://groups.google.com/a/crystal.harvard.edu/g/fischerlab-proteomics</a>
                    </Paragraph>
                </Typography>
                <div>
                    <Modal
                        title="DFCI Proteomics"
                        open={this.props.showLogin}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        footer={null}
                        style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                    >
                        <GoogleButton onClick={this.handleOk} />
                    </Modal>
                </div>
            </>
        }
    }

}

const mapStateToProps = ({experiments, results, token}) => {
    return {
        experiments,
        results,
        showLogin: token.showLogin,
        token: token.token
    }
}

export default connect(mapStateToProps, {
    setClickedAsync,
    setLoading,
    addCustomCompound,
    setSearchedExperiment,
    toggleLoginModal,
})(Home)