import React from 'react';
import {Navigate, Route, Routes, useSearchParams} from "react-router-dom";
import {
    Configuration,
    CustomHeatmap,
    Experiment,
    Group,
    Home,
    Inactive,
    User,
    Plots
} from "../../screens";
import {
    CONFIGURATION_URL,
    EXPERIMENTS_URL,
    GROUPS_URL,
    HEATMAP_URL,
    HOME_URL,
    INACTIVE_LOGIN_URL,
    USERS_URL
} from "../constants";
import {setToken, toggleLoginModal} from "../../features/token/tokenActions";
import {connect} from "react-redux";

const HomeRedirect = ({ location }) => <Navigate to={HOME_URL} />

function ContentArea({ token, setToken }) {
    const [searchParams, setSearchParams] = useSearchParams();

    if (searchParams.has('token') && token !== searchParams.get('token')) {
        setToken(searchParams.get('token'));
    }

    return (
        <div style={{background: '#fff', padding: 24, minHeight: 280}}>
            <div className={'contentarea'}>
                <Routes>
                    <Route
                        element={<Experiment token={token}/>}
                        path={EXPERIMENTS_URL}
                    />
                    <Route
                        element={<Group token={token}/>}
                        path={GROUPS_URL}
                    />
                    <Route
                        element={<User token={token}/>}
                        path={USERS_URL}
                    />
                    <Route
                        element={<Configuration token={token}/>}
                        path={CONFIGURATION_URL}
                    />
                    <Route
                        element={<Inactive token={token}/>}
                        path={INACTIVE_LOGIN_URL}
                    />
                    <Route
                        element={<CustomHeatmap token={token}/>}
                        path={HEATMAP_URL}
                    />
                    <Route
                        element={<Plots token={token}>Where do I show?</Plots>}
                        path={'/plots/:dataType/:id'}
                    />
                    <Route
                        element={
                            <Home
                                token={token}
                                toggleLoginModal={toggleLoginModal}
                            />
                        }
                        path={HOME_URL}
                    />
                    <Route element={<HomeRedirect/>}/>
                </Routes>
            </div>
        </div>
    );
}

function mapStateToProps({ token }) {
    return {
        token: token.token
    };
}

export default connect(mapStateToProps, {
    setToken,
    toggleLoginModal
})(ContentArea);

