export const SET_EXPERIMENTS = 'SET_EXPERIMENTS'
export const SET_SEARCHED_EXPERIMENT = 'SET_SEARCHED_EXPERIMENT'
export const UPDATE_EXPERIMENT = 'UPDATE_EXPERIMENT'

export const SET_ACTIVE_EXPERIMENTS = 'SET_ACTIVE_EXPERIMENTS'
export const UPDATE_ACTIVE_EXPERIMENT = 'UPDATE_ACTIVE_EXPERIMENT'
export const REMOVE_ACTIVE_EXPERIMENT = 'REMOVE_ACTIVE_EXPERIMENT'

export const GET_ALL_TREATMENTS = 'GET_ALL_TREATMENTS'
export const GET_EXPERIMENT_COMPOUNDS = 'GET_EXPERIMENT_COMPOUNDS'
export const GET_ALL_PROTEINS = 'GET_ALL_PROTEINS'